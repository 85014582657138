<main class="main">
    <div class="main-content" style="padding: 10px;">
        <h3>Preview Label</h3>
        <form class="report-form">
            <div class="row">
                <div class="form-group col-sm-2">
                    <label for="year">Model Year</label>
                    <datalist id="year" name="year">
                        <option *ngFor="let yr of years; let i = index;" [value]="yr.field">
                            {{yr.value}}</option>
                    </datalist>
                    <input autocomplete="off" class="form-control" placeholder="Select the Year" id="year" name="year"
                        #select list="year" [(ngModel)]="model.modelYear" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeYear($event, 'year')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="modelType">Model Type</label>
                    <datalist id="modelType" name="modelType">
                        <option *ngFor="let model of modelType; let i = index;" [value]="model.value">{{model.value}}
                        </option>
                    </datalist>
                    <input autocomplete="off" class="form-control" placeholder="Select the Model Type" id="modelType"
                        name="modelType" #select list="modelType" [(ngModel)]="model.modelType"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="changeModelType($event, 'type')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="transmission">Transmission</label>
                    <datalist id="transmission" name="transmission">
                        <option *ngFor="let trns of transmission; let i = index;" [value]="trns.value">{{trns.value}}
                        </option>
                    </datalist>
                    <input autocomplete="off" class="form-control" placeholder="Select the Transmission"
                        id="transmission" name="transmission" #select list="transmission"
                        [(ngModel)]="model.transmission" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeTransmission($event, 'transmission')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="fuelType">Fuel Type</label>
                    <datalist id="fuelType" name="fuelType">
                        <option *ngFor="let ft of ftype; let i = index;" [value]="ft.value">
                            {{ft.value}}</option>
                    </datalist>
                    <input autocomplete="off" class="form-control" placeholder="Select Fuel Type" id="fuelType"
                        name="fuelType" #select list="fuelType" [(ngModel)]="model.fuelType"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="changeFuelType($event, 'fuelType')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="options">Options</label>
                    <datalist id="options" name="options">
                        <option *ngFor="let opt of options; let i = index;" [value]="opt.value">
                            {{opt.value}}</option>
                    </datalist>
                    <input autocomplete="off" class="form-control" placeholder="Select options" id="options"
                        name="options" #select list="options" [(ngModel)]="model.options"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="changeOptions($event, 'options')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="vin">VIN</label>
                    <input autocomplete="off" class="form-control" name="vin" type="text" [(ngModel)]="selectedVIN" value="{{selectedVIN}}"
                        (ngModelChange)="changeVIN($event, 'vin')" placeholder="Enter VIN">
                </div>

                <div class="form-group col-sm report-button-container">
                    <button
                        [disabled]="!model.modelYear || !model.modelType || !model.transmission || !model.fuelType || !model.options || !selectedVIN"
                        class="btn btn-primary" type="submit" iconPos="right" (click)="view()">View <em
                            *ngIf="reportProgress" class="fas fa-spinner fa-spin"></em></button>
                    &nbsp;&nbsp;
                    <button class="btn btn-primary" type="submit" iconPos="right" (click)="clearEntries()">Clear All
                        Filters</button>
                </div>
            </div>
            <div *ngIf="msg.length>0" [hidden]="msg.length<1"
                class="alert alert-danger alert-dismissible fade show w-50" role="alert">
                {{msg}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

        </form>
        <br><br>
        <div class="container-fluid" *ngIf="showFields">
            <h5>Point of Sale Date: {{posDate}}</h5>
            <div class="row report-button-container">
                <div class="form-group col-sm-6">
                    <h6>Full Label Proof can only be viewed only after the fuel economy, parts and product content data has been approved.</h6>
                    <button id="labelButton" class="btn btn-primary" type="submit" iconPos="right"
                        (click)="viewLabel()">View Label Proof <em *ngIf="labelProgress"
                            class="fas fa-spinner fa-spin"></em></button>
                </div>
                
                <div class="form-group col-sm-2" *ngIf="isAdmin || legalApproval">
                    <label for="approveFlag">Approve Label: </label>
                    <div class="ui-g">
                        <p-inputSwitch [(ngModel)]="selectedApproveFlag" aria-required="true" (onChange)="approveLabel()"></p-inputSwitch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>