import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'is-what';
import { LoginService } from '../core/login.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    showFooter: boolean = false;
    constructor(public loginService: LoginService) { }

    currentYear: any;

    ngOnInit() {
        this.currentYear = new Date().getFullYear();
        const userSession = this.loginService.getUserSession();
        if (!isNullOrUndefined(userSession)) {
            this.showFooter = true;
        }

        this.loginService.loggedIn$.subscribe(response => {
            if (response['loggedIn']) {
                this.showFooter = true;
            }
            else
                this.showFooter = false;
        });
    }

}