import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import axios from 'axios';

import { Model } from '../model/model';

@Injectable({
    providedIn: 'root'
})
export class LookupDataService {

    constructor(private http: HttpClient) {

    }

    getLookupData(type: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.serviceUrl}secure/api/lookupData/${type}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            });
    }

    getModelTypeLookupDataByYear(year: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.serviceUrl}secure/api/getModelTypeLookupDataByYear/${year}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            });
    }

    getTransmissionLookupDataByYearAndType(year: string, modelType: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.serviceUrl}secure/api/getTransmissionLookupDataByYearAndType/${year}/${modelType}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            });
    }

    getFuelTypeLookupDataByYearTypeAndTransmission(year: string, modelType: string, transmission: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.serviceUrl}secure/api/getFuelTypeLookupDataByYearTypeAndTransmission/${year}/${modelType}/${transmission}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            });
    }

    getOptionsLookupDataByYearTypeTransmAndFuelType(year: string, modelType: string, transmission: string, fuelType: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.serviceUrl}secure/api/getOptionsLookupDataByYearTypeTransmAndFuelType/${year}/${modelType}/${transmission}/${fuelType}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            });
    }

    getVINsLookupDataByYearTypeAndTransm(year: string, modelType: string, transmission: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.serviceUrl}secure/api/getVINsLookupDataByYearTypeAndTransm/${year}/${modelType}/${transmission}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            });
    }

    getOtherFields(modelYear: string, modelType: string, transmission: string, fuelType: string, options: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.serviceUrl}secure/api/lookupOtherFields/${modelYear}/${modelType}/${transmission}/${fuelType}/${options}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            });
    }

    async getPOSDate(modelYear: string, modelType: string, transmission: string) {
        const result = await axios.get<string>(`${environment.serviceUrl}secure/api/getPOSDate/${modelYear}/${modelType}/${transmission}`,
            {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                }
            });
        return result.data;
    }

    async getLegalApprovalFlag(modelYear: string, modelType: string, transmission: string) {
        const result = await axios.get<string>(`${environment.serviceUrl}secure/api/getLegalApprovalFlag/${modelYear}/${modelType}/${transmission}`,
            {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                }
            });
        return result.data;
    }
}
