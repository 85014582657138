import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import axios from 'axios';

import { FuelEconomy } from '../model/fuelEconomy';
import { LoginService } from '../core/login.service';

import { FuelEconomyDataUpdate } from '../model/fuelEconomyDataUpdate';

@Injectable({
    providedIn: 'root'
})
export class FuelEconomyContentService {

    constructor(private http: HttpClient, private loginService: LoginService) { }

    public async getFuelEconomyContent(modelYear: string, modelType: string, transmission: string, fuelType: string, options: string): Promise<FuelEconomy> {
        return this.http.get<FuelEconomy>(`${environment.serviceUrl}secure/api/getFEContentData?modelYear=${modelYear}&modelType=${modelType}&transmission=${transmission}&fuelType=${fuelType}&options=${options}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            }).toPromise();
    }

    public async updateFuelEconomyData(fuelEconomyData: FuelEconomyDataUpdate): Promise<FuelEconomyDataUpdate> {        
        const result = await axios.post<FuelEconomyDataUpdate>(`${environment.serviceUrl}secure/api/updateFuelEconomyData`, fuelEconomyData,            
            {
                headers: {
                    'Authorization': this.getToken(),
                    'Content-Type': 'application/json'
                }
            });
        return result.data;
    }

    public async approveFuelEconomyData(modelYear: string, modelType: string, transmission: string, fuelType: string, options: string) {
        const userID = this.loginService.getUserSession()['userName'];
        const result = await axios.post<boolean>(`${environment.serviceUrl}secure/api/approveFuelEconomyData`, 
        { modelYear: modelYear, modelType: modelType, transmission: transmission, fuelType: fuelType, options: options, userID: userID }, 
        { 
            headers: { 
                'Authorization': this.getToken(), 
                'Content-Type': 'application/json' 
            } 
        });
        return result.data;
    }

    protected getToken(): string {
        return 'Bearer ' + sessionStorage.getItem("token");
    }
}