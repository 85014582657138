import { Component, OnInit } from '@angular/core';
import { LoginService } from '../core/login.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'is-what';

declare var $: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  showNav: boolean = false;
  username: string;
  userFullName: string;
  emulating: boolean = false;

  isAdmin: boolean = false;
  isSuperUser: boolean = false;
  fuelTab: boolean = false;
  partsTab: boolean = false;
  productTab: boolean = false;
  previewLabelTab: boolean = false;

  constructor(public loginService: LoginService, private router: Router) { }

  ngOnInit() {
    const userSession = this.loginService.getUserSession();
    if(isNullOrUndefined(userSession)) {
      this.router.navigate(['login']);
    }

    if (!isNullOrUndefined(userSession)) {
      this.showNav = true;
      this.username = userSession.userName;      
    }

    this.loginService.loggedIn$.subscribe(response => {
      if (response['loggedIn']) {
        this.showNav = true;
        this.username = response['userName'];
        this.showTabs();
      } else {
        this.showNav = false;
      }
    });

    this.showTabs();
  }

  showTabs() {        
    this.loginService.getLoggedInUserName(this.username).then((resp) => {
      this.userFullName = resp;
    });   
        
    let roles = JSON.parse(sessionStorage.getItem("userRoles"));
    
    if (roles === null) {      
      this.loginService.getUserRoles(this.username).subscribe(resp => {
        roles = resp;
        this.assignRoles(roles);
      });
    }

    this.assignRoles(roles);
  }

  assignRoles(roles) {
    for (var i in roles) {
      if (roles[i].roleID === 1) {
        this.isAdmin = true;
        this.isSuperUser = true;
        this.fuelTab = true;
        this.partsTab = true;
        this.productTab = true;
      } else if (roles[i].roleID === 6) {
        this.fuelTab = true;        
      } else if (roles[i].roleID === 7) {
        this.productTab = true;
      } else if (roles[i].roleID === 8) {
        this.partsTab = true;
      } else if (roles[i].roleID === 9 || roles[i].roleID === 10) {
        this.previewLabelTab = true;
      }
    }
  }

  toggle() {
    $('.navbar-primary').toggleClass('collapsed');
    if ($(".collapsed")[0]) {
      $('.main-content').css('margin-left', '0px');
      $('.app-footer').css('margin-left', '0px');
    } else {
      $('.main-content').css('margin-left', '260px');
      $('.app-footer').css('margin-left', '260px');
    }
    this.resizeBroadcast();
  }

  logout() {
    event.preventDefault();
    this.showNav = false;
    this.isAdmin = false;
    this.isSuperUser = false;
    this.fuelTab = false;
    this.partsTab = false;
    this.productTab = false;
    this.loginService.logout('success');
  }

  stopEmulating() {
    // event.preventDefault();
    // this.loginService.stopEmulating();
    this.emulating = false;
    this.router.navigate(['/home']);
    sessionStorage.setItem('emulatedUserID', '');
  }

  updateActiveLink(url: string) {
    const currentlyActive = document.querySelectorAll("li.active");
    if (currentlyActive != null && currentlyActive.length > 0) {
      for (let index = 0; index < currentlyActive.length; index++) {
        currentlyActive.item(index).classList.remove("active");
      }
    }

    const activeLink = document.querySelector(`li a[routerLink='${url}']`);
    if (activeLink != null) {
      const activeItem = activeLink.parentElement;
      if (activeItem != null) {
        activeItem.classList.add("active");
      }
    }
  }

  resizeBroadcast() {
    var timesRun = 0;
    var interval = setInterval(function () {
      timesRun += 1;
      if (timesRun === 5) {
        clearInterval(interval);
      }
      window.dispatchEvent(new Event('resize'));
    }, 62.5);
  }
}
