import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import axios from 'axios';

import { LoginService } from '../core/login.service';

const urlConfig = {
    apiProofURL: 'https://helmapi.cierant.com/api/uProduce/GetHelmProof'
};

@Injectable({
    providedIn: 'root'
})
export class APIService {

    constructor(private http: HttpClient, private loginService: LoginService) { }

    // public async getAPIAuthorizationToken(): Promise<Observable<string>> {
    //     return this.http.get<string>(`${environment.serviceUrl}secure/api/getAPIAuthorizationToken`,
    //         {
    //             headers: new HttpHeaders({
    //                 'Authorization': `Bearer ${sessionStorage.getItem("token")}`
    //             })
    //         })
    // }

    getAPIAuthorizationToken(): Promise<any> {
        var output: Observable<any> = this.http.get<string>(`${environment.serviceUrl}secure/api/getAPIAuthorizationToken`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            })
        return output.toPromise();
    }

    blob: Blob;
    getLabelProofWithToken(templateID: string, modelYear: string, modelType: string, transmission: string, token: string) {        

        this.http.post(urlConfig.apiProofURL, {
            "TemplateID": templateID,
            "ModelType": modelType,
            "ModelYear": modelYear,
            "TransmissionDataID": transmission
        }, {
            responseType: 'blob',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Authorization': token,
            }
        }).subscribe({
            next: (data) => {                
                this.blob = new Blob([data], { type: 'application/pdf' });
                var binaryData = [];
                binaryData.push(data);
                var downloadURL = window.URL.createObjectURL(new Blob(binaryData));
                // var downloadURL = window.URL.createObjectURL(data);
                var link = document.createElement('a');
                link.href = downloadURL;
                // link.download = "proof.pdf";
                link.setAttribute('target', '_blank');
                link.click();
            },
            error: (error) => {
                console.error(error);
            }
        });

        // try {
        //     axios({
        //         url: `${urlConfig.apiProofURL}`,
        //         method: 'post',                
        //         headers: {
        //             Accept: 'application/json',
        //             'Content-Type': 'application/json',
        //             'Access-Control-Allow-Origin': '*',
        //             'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        //             'Authorization': token,
        //         },
        //         responseType: 'blob',
        //         data: {
        //             "TemplateID": "8941",
        //             "ModelType": "970820",
        //             "ModelYear": "2013",
        //             "TransmissionDataID": "17",
        //             "VINNumber": ["WP0AA2A72DL014544", "WP0AA2A79DL014735"],
        //             "FuelEcoNumber": ""
        //         }
        //     })
        //     .then(function (response) {
        //         // res = response.data;
        //         // const content = response.headers['content-type'];
        //         // download(response, "proof", content);
        //         window.open(URL.createObjectURL(response.data));        
        //         // return Promise.resolve(res);
        //         // const a = document.createElement('a');
        //         // a.href = window.URL.createObjectURL(response.data);
        //         // a.download = `proof.pdf`;
        //         // document.body.appendChild(a);
        //         // a.click();
        //         // a.remove();
        //     })
        //     .catch(error => {
        //         console.error(`Error log from catch block -> response api: ${error}`);
        //     });                                  
        // } catch (e) {
        //     console.error(`Error while connecting to Proof API: ${e}`);            
        // }
    }

    public async getLabelProofPostcall(templateID: string, modelYear: string, modelType: string, transmission: string, vin: string[]): Promise<any> {
        const result = await axios.post<boolean>(`${environment.serviceUrl}secure/api/getLabelProofPostCall`,
            {
                'templateID': templateID,
                'modelYear': modelYear,
                'modelType': modelType,
                'transmission': transmission,
                'vin': vin
            },
            {
                headers: {
                    'Authorization': this.getToken(),
                    'Content-Type': 'application/json'
                }
            });
        return result.data;
    }

    getLabelProofForProduct(templateID: string, modelYear: string, modelType: string, transmission: string, vin: string[]): Observable<Blob> {
        return this.http.get(`${environment.serviceUrl}secure/api/getLabelProofForProduct`,
            {
                responseType: 'blob',
                params:
                {
                    'templateID': templateID,
                    'modelYear': modelYear,
                    'modelType': modelType,
                    'transmission': transmission,
                    'vin': vin
                }
            });
    }

    getLabelProofForFE(templateID: string, modelYear: string, modelType: string, transmission: string, fuelType: string, options: string): Observable<Blob> {
        return this.http.get(`${environment.serviceUrl}secure/api/getLabelProofForFE`,
            {
                responseType: 'blob',
                params:
                {
                    'templateID': templateID,
                    'modelYear': modelYear,
                    'modelType': modelType,
                    'transmission': transmission,
                    'fuelType': fuelType,
                    'options': options
                }
            });
    }

    getLabelProofForParts(templateID: string, modelYear: string, modelType: string, transmission: string): Observable<Blob> {
        return this.http.get(`${environment.serviceUrl}secure/api/getLabelProofForParts`, {
            responseType: 'blob',
            params:
            {
                'templateID': templateID,
                'modelYear': modelYear,
                'modelType': modelType,
                'transmission': transmission
            }
        });
    }

    getFullLabelProof(templateID: string, modelYear: string, modelType: string, transmission: string, vin: string[], fuelType: string, options: string): Observable<Blob> {
        return this.http.get(`${environment.serviceUrl}secure/api/getFullLabelProof`, {
            responseType: 'blob',
            params:
            {
                'templateID': templateID,
                'modelYear': modelYear,
                'modelType': modelType,
                'transmission': transmission,
                'vin': vin,
                'fuelType': fuelType,
                'options': options
            }
        });
    }

    protected getToken(): string {
        return 'Bearer ' + sessionStorage.getItem("token");
    }

    private handleError(error: Response | any) {
        let errMsg: string = "";
        if (error instanceof Response) {
            const body: any = error || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        return throwError(errMsg);
    }
}