<main class="main">
    <div class="main-content" style="padding: 10px;">
        <h3>Parts Content Information</h3>
        <form class="report-form">
            <div class="row">
                <div class="form-group col-sm-2">
                    <label for="year">Model Year</label>
                    <datalist id="year" name="year">
                        <option *ngFor="let yr of years; let i = index;" [value]="yr.field">{{yr.value}}</option>
                    </datalist>
                    <input autocomplete="off" class="form-control" placeholder="Select the Year" id="year" name="year"
                        #select list="year" [(ngModel)]="model.modelYear" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeYear($event, 'year')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="modelType">Model Type</label>
                    <datalist id="modelType" name="modelType">
                        <option *ngFor="let model of modelType; let i = index;" [value]="model.value">{{model.value}}
                        </option>
                    </datalist>
                    <input autocomplete="off" class="form-control" placeholder="Select the Model Type" id="modelType"
                        name="modelType" #select list="modelType" [(ngModel)]="model.modelType"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="changeModelType($event, 'type')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="transmission">Transmission</label>
                    <datalist id="transmission" name="transmission">
                        <option *ngFor="let trns of transmission; let i = index;" [value]="trns.value">{{trns.value}}
                        </option>
                    </datalist>
                    <input autocomplete="off" class="form-control" placeholder="Select the Transmission"
                        id="transmission" name="transmission" #select list="transmission"
                        [(ngModel)]="model.transmission" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeTransmission($event, 'transmission')" />
                </div>

                <div class="form-group col-sm report-button-container">
                    <button [disabled]="!model.modelYear || !model.modelType || !model.transmission"
                        class="btn btn-primary" type="submit" iconPos="right" (click)="view()">View <em
                            *ngIf="reportProgress" class="fas fa-spinner fa-spin"></em></button>
                    &nbsp;&nbsp;
                    <button class="btn btn-primary" type="submit" iconPos="right" (click)="clearEntries()">Clear All
                        Filters</button>
                </div>
            </div>
            <!-- <div class="row">
                <div class="form-group col-sm-2">
                    <label for="year">Model Year</label>
                    <select class="form-control" id="year" required [(ngModel)]="model.modelYear" name="year" (ngModelChange)="changeYear($event, 'year')">
                        <option value="" disabled selected>Select the Year</option>
                        <option *ngFor="let yr of years; let i = index;" [value]="yr.field">{{yr.value}}</option>
                    </select>                   
                </div>

                <div class="form-group col-sm-2">
                    <label for="modelType">Model Type</label>
                    <select class="form-control" id="modelType" required [(ngModel)]="model.modelType" name="modelType" (ngModelChange)="changeModelType($event, 'type')">
                        <option value="" disabled selected>Select the Model</option>
                        <option *ngFor="let model of modelType; let i = index;" [value]="model.field">{{model.value}}</option>
                    </select>                   
                </div>

                <div class="form-group col-sm-2">
                    <label for="transmission">Transmission</label>
                    <select class="form-control" id="transmission" required [(ngModel)]="model.transmission" name="transmission" (ngModelChange)="changeTransmission($event, 'transmission')">
                        <option value="" disabled selected>Select the Transmission</option>
                        <option *ngFor="let trns of transmission; let i = index;" [value]="trns.field">{{trns.value}}</option>
                    </select>
                </div>
                
                <div class="form-group col-sm-2 report-button-container">
                    <button class="btn btn-primary" type="submit" iconPos="right" (click)="view()">View <em
                            *ngIf="reportProgress" class="fas fa-spinner fa-spin"></em></button>
                </div>
            </div> -->
            <div *ngIf="msg.length>0" [hidden]="msg.length<1"
                class="alert alert-danger alert-dismissible fade show w-50" role="alert">
                {{msg}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </form>

        <div class="container-fluid" *ngIf="showFields">
            <h5>Click on Approve, if the fields are correct </h5>
            <div class="report-button-container">
                <button class="btn btn-primary" type="submit" id="approveButton"
                    [disabled]="partsContent.approveFlag === 'Y'" iconPos="right" (click)="approveData()">Approve Data
                    <em *ngIf="approveProgress" class="fas fa-spinner fa-spin"></em></button>
            </div>
            <table style="width: 475px; height: 380px;">
                <tbody>
                    <tr>
                        <td colspan="2" style="text-align: right;">
                            <div style="text-align: right;" class="report-button-container">
                                <h6>Label Proof can be done only after the parts content information has been approved.</h6>
                                <button id="labelButton" class="btn btn-primary" type="submit" iconPos="right"
                                    (click)="viewLabel()">View Label Proof <em *ngIf="labelProgress"
                                        class="fas fa-spinner fa-spin"></em></button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align: right;">
                            <h5>Point of Sale Date: {{posDate}}</h5>
                        </td>
                    </tr>                    
                    <tr>
                        <td>U.S./Canadian Parts Content</td>
                        <td><input name="partsContent" type="text" class="validate"
                                [(ngModel)]="partsContent.partsContent" value="{{partsContent.partsContent}}">%</td>
                    </tr>
                    <tr>
                        <td>Foreign Source1</td>
                        <td><input name="foreignSource1" type="text" class="validate"
                                [(ngModel)]="partsContent.foreignSource1" value="{{partsContent.foreignSource1}}">%
                        </td>
                    </tr>
                    <tr>
                        <td>Foreign Content1</td>
                        <td><input name="foreignContent1" type="text" class="validate"
                                [(ngModel)]="partsContent.foreignContent1" value="{{partsContent.foreignContent1}}">%
                        </td>
                    </tr>
                    <tr>
                        <td>Foreign Source2</td>
                        <td><input name="foreignSource2" type="text" class="validate"
                                [(ngModel)]="partsContent.foreignSource2" value="{{partsContent.foreignSource2}}">%
                        </td>
                    </tr>
                    <tr>
                        <td>Foreign Content2</td>
                        <td><input name="foreignContent2" type="text" class="validate"
                                [(ngModel)]="partsContent.foreignContent2" value="{{partsContent.foreignContent2}}">%
                        </td>
                    </tr>
                    <tr>
                        <td>Final Assembly Point</td>
                        <td><input name="assembly" type="text" class="validate" [(ngModel)]="partsContent.assembly"
                                value="{{partsContent.assembly}}"></td>
                    </tr>
                    <tr>
                        <td>Engine (Country of Origin)</td>
                        <td><input name="engineOrigin" type="text" class="validate"
                                [(ngModel)]="partsContent.engineOrigin" value="{{partsContent.engineOrigin}}"></td>
                    </tr>
                    <tr>
                        <td>Transmission (Country of Origin)</td>
                        <td><input name="transmissionOrigin" type="text" class="validate"
                                [(ngModel)]="partsContent.transmissionOrigin"
                                value="{{partsContent.transmissionOrigin}}"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right;"><button class="btn btn-primary" id="updateButton" type="submit" [disabled]="partsContent.updateFlag === 'Y'"
                                iconPos="right" (click)="updateData()">Update <em *ngIf="updateProgress"
                                    class="fas fa-spinner fa-spin"></em></button></td>
                    </tr>
                </tbody>
            </table>

            <!-- <div id="outerContainer">
                <div class="pdf-container">
                  <pdf-viewer
                    [src]="pdfSRC"
                    [rotation]="0"
                    [original-size]="false"
                    [show-all]="true"
                    [fit-to-page]="false"
                    [zoom]="1"
                    [zoom-scale]="'page-width'"
                    [stick-to-page]="false"
                    [render-text]="true"
                    [external-link-target]="'blank'"
                    [autoresize]="true"
                    [show-borders]="false"
                    style="width: 100%; height: 100vh;"
                  ></pdf-viewer>
                </div>
              </div> -->

        </div>
    </div>
</main>