import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { LoginComponent } from './core/login/login.component';
import { HomeComponent } from './home/home.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { ProductContentComponent } from './productContent/productContent.component';
import { PartsContentComponent } from './partsContent/partsContent.component';
import { FuelEconomyComponent } from './fuelEconomy/fuelEconomy.component';
import { ContactPageComponent } from './contact/contact.component';
import { AdminPageComponent } from './admin/adminPage.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PreviewLabelComponent } from './labelPreview/labelPreview.component';

import { DropdownModule } from 'primeng/dropdown';
import { SpinnerModule } from 'primeng/spinner';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { ButtonModule } from "primeng/button";
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavComponent,
    FooterComponent,
    ProductContentComponent,
    PartsContentComponent,
    FuelEconomyComponent,
    ContactPageComponent,
    AdminPageComponent,
    DashboardComponent,
    PreviewLabelComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    DropdownModule,
    SpinnerModule,
    CalendarModule,
    RadioButtonModule,
    FileUploadModule,    
    TagModule,
    TableModule,
    ButtonModule,
    InputSwitchModule
  ],
  providers: [
    { provide: ErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
