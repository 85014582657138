<nav class="navbar header_out" aria-label="header">
  <div class="container-fluid">
    <div class="navbar-header">
      <a class="navbar-brand" href="#"><img src="./header_logo.png" style="height:88px;" alt="Header Logo" /></a>
    </div>
    <div id="navbar">
      <div class="mr-auto">
      </div>
      <span class="navbar-text">
        <a routerLink="/login" href="#">Login</a>
      </span>
    </div>
  </div>
</nav>
<div class="card m-5 w-25 shadow card-style">
  <div class="card-body">
    <form>
      <div class="text-secondary"><strong>LOGIN</strong></div>
      <div class="form-group">
        <label for="username">User Name</label>
        <input autofocus (click)="loginError=false" (input)="loginError=false" name="username" type="text"
          class="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2"
          [(ngModel)]="user.username">
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input (click)="loginError=false" (input)="loginError=false" name="password" type="password"
          class="form-control" id="password" [(ngModel)]="user.password">
      </div>
      <button [disabled]="!user.username || !user.password" type="submit" class="btn btn-primary" (click)="login()">
        Login <em *ngIf="loginProgress" class="fas fa-spinner fa-spin"></em>
      </button>
      <div *ngIf='loginError'><small class="text-danger"><strong>{{loginErrorText}}</strong></small></div>
      <!-- <div class="mt-3">
          New User? <a class="ml-1" routerLink="/register">Register</a>
        </div> -->
      <!-- <div class="mt-3">
          Forgot Password? <a class="ml-1" routerLink="/passwordResetRequest">Reset Password</a>
        </div> -->
    </form>
  </div>
</div>