import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import axios from 'axios';

import { ProductContent } from '../model/productContent';
import { LoginService } from '../core/login.service';

@Injectable({
    providedIn: 'root'
})
export class ProductContentService {

    constructor(private http: HttpClient, private loginService: LoginService) { }

    public async getProductContentData(modelYear: string, modelType: string, transmission: string, vin: string): Promise<ProductContent> {
        return this.http.get<ProductContent>(`${environment.serviceUrl}secure/api/getProductContentData?modelYear=${modelYear}&modelType=${modelType}&transmission=${transmission}&vin=${vin}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            }).toPromise();
    }

    public async approveProductContentData(modelYear: string, modelType: string, transmission: string, vin: string) {
        const userID = this.loginService.getUserSession()['userName'];
        const result = await axios.post<boolean>(`${environment.serviceUrl}secure/api/approveProductContentData`, 
        { modelYear: modelYear, modelType: modelType, transmission: transmission, vin: vin, userID: userID }, 
        { 
            headers: { 
                'Authorization': this.getToken(), 
                'Content-Type': 'application/json' 
            } 
        });
        return result.data;

        // return this.http.post<boolean>(`${environment.serviceUrl}secure/api/approveProductContentData`,
        //     { modelYear: modelYear, modelType: modelType, transmission: transmission, vin: vin, userID: userID },
        //     {
        //         headers: new HttpHeaders({
        //             'Authorization': this.getToken()
        //         })
        //     });
    }

    protected getToken(): string {
        return 'Bearer ' + sessionStorage.getItem("token");
    }

    private handleError(error: Response | any) {
        let errMsg: string = "";
        if (error instanceof Response) {
            const body: any = error || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        return throwError(errMsg);
    }
}