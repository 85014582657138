import { Column } from "./column";

export class DataTable 
{
    name: string;
    columns: Column[];
    records: any[];
    finalTotal: number;
    approveFlag: string;
    bulletRequired: number;
}