import { Component, OnInit, ViewChild, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavComponent } from './nav/nav.component';
import { LoginService } from './core/login.service';

import { PrimeNGConfig } from 'primeng/api';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  @ViewChild(NavComponent, { static: false }) nav: NavComponent;

  constructor(
    private router: Router,        
    private chRef: ChangeDetectorRef,
    private primengConfig: PrimeNGConfig,
    private loginService: LoginService,
    ngZone: NgZone
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.nav.updateActiveLink(event.url);
        const session = JSON.parse(sessionStorage.getItem('userSession'));
        let userId = "";
        if (session != null) {
          userId = session["userName"];
        }        
      }
    });
    window.onresize = (e) => {
        ngZone.run(() => {
            this.resizeMe();
        });
    };
  }

  public ngOnInit() {
    this.primengConfig.ripple = true;
    this.chRef.detectChanges();
    this.resizeMe();
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });
  }

  public resizeMe() {
      var ht = window.innerHeight;      
      ht = ht - 150;      
      $("main").css({
          "min-height": ht + "px",
          "padding-bottom": "2rem",
          "margin-left": "0px",
      });
  }

}
