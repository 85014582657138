import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalErrorHandler } from './globalerrorhandler';
import { isNullOrUndefined } from 'is-what';
import { TokenVerificationService } from './token-verification.service';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private loggedIn = new Subject<Object>();
  loggedIn$ = this.loggedIn.asObservable();

  private loggedOut = new Subject<Object>();
  loggedOut$ = this.loggedOut.asObservable();

  constructor(private http: HttpClient, private router: Router, private tokenService: TokenVerificationService, private errorHandler: GlobalErrorHandler) { }

  public setTokenInSession(token: string) {
    return sessionStorage.setItem('token', token);
  }

  public getTokenFromSession() {
    return sessionStorage.getItem('token');
  }

  public setLogin() {
    const session = this.getUserSession();
    if (session != null) {
      var username = this.getUserSession()['userName'];      
      this.loggedIn.next({
        loggedIn: true,
        userName: username
      });
    }
  }

  public getUserName() {
    return JSON.parse(sessionStorage.getItem('userName'));
  }

  public setUserName(user: any) {
    return sessionStorage.setItem('userName', JSON.stringify(user));
  }

  public setUserSession(user: any) {
    return sessionStorage.setItem('userSession', JSON.stringify(user));
  }

  public getUserSession() {
    return JSON.parse(sessionStorage.getItem('userSession'));
  }

  public setOriginalUserSession(user: any) {
    return sessionStorage.setItem('originalUserSession', JSON.stringify(user));
  }

  public logout(condition: string) {
    sessionStorage.clear();
    this.router.navigate(['/login'], {
      queryParams: { 'logout': condition }
    });
    this.loggedIn.next({
      loggedIn: false,
      username: ''
    });
    this.loggedOut.next({
      loggedOut: true,
      username: '',
      showNav: false
    });
  }

  public async isValidToken(token): Promise<boolean> {
    if (isNullOrUndefined(token)) {
      return Promise.resolve(false);
    }

    try {
      var data = await this.tokenService.verifyTokenAsync(token);
      if (isNullOrUndefined(data) || isNullOrUndefined(data.data)) {
        return Promise.resolve(false);
      } else {
        this.setUserSession(data.data);
        return Promise.resolve(true);
      }
    } catch (e) {
      this.handleError(e, 'isValidToken');
    }

    return Promise.resolve(false);
  }

  public setUserRolesInSession(userRoles: any[]) {
    return sessionStorage.setItem('userRoles', JSON.stringify(userRoles));
  }

  public getUserRolesFromSession() {
    return JSON.parse(sessionStorage.getItem('userRoles'));
  }

  public setUserFullNameInSession(userFullName: any) {
    return sessionStorage.setItem('userFullName', JSON.stringify(userFullName));
  }

  public getUserFullNameFromSession() {
    return JSON.parse(sessionStorage.getItem('userFullName'));
  }

  getUserRoles(userId: string): Observable<any> {
    return this.http.get<any[]>(`${environment.serviceUrl}secure/api/useroles/${userId}`,
      {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        })
      });
  }

  public async getLoggedInUserName(userId: string) {    
    const result = await axios.get<string>(`${environment.serviceUrl}secure/api/getLoggedInUserName/${userId}`,    
    {
      headers: { 
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      }
    });
    return result.data;
  }

  public async getModelYears(): Promise<string[]> {
    let user: any;
    user = this.getUserSession().data.username;

    const token = this.getTokenFromSession();

    const response = await this.http.post<any>(environment.serviceUrl + 'public/api/modelYears', JSON.stringify({ username: user }), {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json'), responseType: 'json'
    }).toPromise();
    return Promise.resolve(response);
  }

  public handleError(error: HttpErrorResponse, method: string) {
    let message = error.message ? error.message : 'Unable to process your request at this time.';
    this.errorHandler.handleError(error);
    return throwError(() => message);
  }
}