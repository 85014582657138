import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TokenVerificationService {
    constructor(private http: HttpClient) { }

    verifyToken(token: string): Observable<any> {
        let output: Observable<any> = this.http.get<any>(environment.serviceUrl + 'public/api/verifyToken',
            {
                headers:
                    { 'Authorization': 'Bearer ' + token }
            })
        return output;
    }

    verifyTokenAsync(token: string): Promise<any> {
        let output: Observable<any> = this.http.get<any>(environment.serviceUrl + 'public/api/verifyToken',
            {
                headers:
                {
                    'Authorization': 'Bearer ' + token
                }
            })
        return output.toPromise();
    }

    getToken(userName: string, password: string): Promise<any> {
        var output: Observable<any> = this.http.get<any>(environment.serviceUrl + 'public/api/getToken',
            {
                params:
                {
                    'userName': userName,
                    'password': password
                }
            })
        return output.toPromise();
    }
}