import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { DashboardPage } from '../model/dashboardPage';
import { LoginService } from '../core/login.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(private http: HttpClient, private loginService: LoginService) { }

    public async getDashboardData(modelYear: string, modelType: string, transmission: string): Promise<DashboardPage> {
        return this.http.get<DashboardPage>(`${environment.serviceUrl}secure/api/getDashboardData?modelYear=${modelYear}&modelType=${modelType}&transmission=${transmission}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            }).toPromise();
    }
    
}