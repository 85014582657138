import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { LoginService } from '../core/login.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'is-what';

import { Model } from '../model/model';
import { DataTable } from '../model/dataTable';
import { EmailProperties } from '../model/emailProperties';
import { AdminPage } from '../model/adminPage';

import { LookupDataService } from '../services/lookup-data.service';
import { ProductContentService } from '../services/productContent.service';
import { APIService } from '../services/api.service';
import { AdminPageService } from '../services/adminPage.service';
import { EmailService } from '../services/email.service';

import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-product',
    templateUrl: './productContent.component.html',
    styleUrls: ['./productContent.component.css']
})
export class ProductContentComponent implements OnInit {

    reportProgress: boolean = false;
    updateProgress: boolean = false;
    approveProgress: boolean = false;
    labelProgress: boolean = false;

    viewReport: boolean = false;

    msg: string = "";

    years: any[];
    modelType: any[];
    transmission: any[];
    ftype: any[];
    options: any[];

    selectedYear: string;
    selectedModelType: string;
    selectedTransmission: string;

    selectedVIN: any = "";
    vins: any[];

    dataTable: DataTable;

    model = new Model('', '', '', '', '');
    emailProperties: EmailProperties;

    constructor(private sanitizer: DomSanitizer,
        private changeDetector: ChangeDetectorRef,
        public loginService: LoginService,
        private lookupService: LookupDataService,
        private productContentService: ProductContentService,
        private adminPageService: AdminPageService,
        private emailService: EmailService,
        private apiService: APIService,
        private router: Router,
        private titleService: Title) {
        this.titleService.setTitle("Product Content");
        this.getModelYears();
        this.getModelType();
        this.getTransmissions();
        // this.getVINs();
    }

    userSession: any;
    ngOnInit() {
        this.userSession = this.loginService.getUserSession();
        if (isNullOrUndefined(this.userSession)) {
            this.router.navigate(['login']);
        }
    }

    getModelYears() {
        if (!this.years) {
            this.getLookupData('modelYear').subscribe(resp => {
                this.years = resp;
            });
        }
    }

    getModelType() {
        if (!this.modelType) {
            this.getLookupData('modelType').subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    getTransmissions() {
        if (!this.transmission) {
            this.getLookupData('transmission').subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    getVINs() {
        if (!this.vins) {
            this.getLookupData('vins').subscribe(resp => {
                this.vins = resp;
            });
        }
    }

    getLookupData(type: string) {
        return this.lookupService.getLookupData(type);
    }

    changeYear(event, type) {        

        this.selectedYear = this.model.modelYear;

        if (this.selectedYear != null) {
            this.lookupService.getModelTypeLookupDataByYear(this.selectedYear).subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    changeModelType(event, type) {        

        if (this.modelType != null) {
            for (var i in this.modelType) {
                if (this.model.modelType === this.modelType[i].value) {
                    this.selectedModelType = this.modelType[i].field;
                }
            }
        }

        if (this.selectedYear != null && this.selectedModelType != null) {
            this.lookupService.getTransmissionLookupDataByYearAndType(this.selectedYear, this.selectedModelType).subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    changeTransmission(event, type) {        

        if (this.transmission != null) {
            for (var i in this.transmission) {
                if (this.model.transmission === this.transmission[i].value) {
                    this.selectedTransmission = this.transmission[i].field;
                }
            }
        }

        // if (this.selectedYear != null && this.selectedModelType != null && this.selectedTransmission != null) {
        //     this.lookupService.getVINsLookupDataByYearTypeAndTransm(this.selectedYear, this.selectedModelType, this.selectedTransmission).subscribe(resp => {
        //         this.vins = resp;
        //     });
        // }
    }

    changeVIN(event, type) {        

        this.getPosDate(this.selectedYear, this.selectedModelType, this.selectedTransmission);
        this.getAdminProductButtonStatus(this.selectedYear, this.selectedModelType, this.selectedTransmission);
    }

    clearEntries() {
        this.model.modelType = "";
        this.model.modelYear = "";
        this.model.transmission = "";
        this.selectedVIN = "";
        this.selectedYear = "";
        this.selectedModelType = "";
        this.selectedTransmission = "";
        this.msg = "";
        this.to = "";
        this.subject = "";
        this.message = "";
        this.cc = "";
        this.bcc = "";
        this.releaseEmails = "";
        this.posDate = "";
        this.viewReport = false;
    }

    posDate: string;
    getPosDate(selectedYear, selectedModelType, selectedTransmission) {
        this.lookupService.getPOSDate(selectedYear, selectedModelType, selectedTransmission).then((posDate) => {
            this.posDate = posDate;
        });
    }

    adminProductButtonStatus: string;
    adminPage: AdminPage;

    async getAdminProductButtonStatus(selectedYear, selectedModelType, selectedTransmission) {
        (await this.adminPageService.getAdminPageComponents(selectedYear, selectedModelType, selectedTransmission)).subscribe(resp => {
            this.adminPage = resp;
            this.adminProductButtonStatus = this.adminPage.enableProductButtons;
            console.log("Admin Product Button Status: ", this.adminProductButtonStatus);            
        });
    }

    motor: string;
    horsePower: string;
    transmissionDescription: string;
    async showReport() {        

        this.reportProgress = true;
        this.viewReport = false;
        this.msg = "";
        if (this.selectedYear != null && this.selectedModelType != null && this.selectedTransmission != null && this.selectedVIN != null) {
            const productContentData = await this.productContentService.getProductContentData(this.selectedYear, this.selectedModelType, this.selectedTransmission, this.selectedVIN);
            const dataTable = new DataTable();
            dataTable.name = "Product Content";
            dataTable.columns = [
                { field: 'ID', header: 'VehicleOptions ID', dataType: "string" },
                { field: 'OptionCode', header: 'Option Code', dataType: "string" },
                { field: 'OptionText', header: 'Option Text', dataType: "string" },
                { field: 'OptionPrice', header: 'Option Price', dataType: "number" }
            ];
            dataTable.records = productContentData.productOptions;
            dataTable.records = dataTable.records.map(pc => {
                return {
                    ID: pc.ID,
                    OptionCode: pc.OptionCode,
                    OptionText: pc.OptionText,
                    OptionPrice: pc.OptionPrice,
                    approveFlag: pc.approveFlag
                };
            });
            dataTable.finalTotal = 0;
            dataTable.records.forEach(pc => {
                dataTable.finalTotal = dataTable.finalTotal + pc.OptionPrice;
                dataTable.approveFlag = pc.approveFlag;
                dataTable.bulletRequired = pc.bulletRequired;
            });
            if (this.selectedYear <= '2023') {
                dataTable.approveFlag = 'Y';
            }
            if(this.adminProductButtonStatus === "Y") {                
                dataTable.approveFlag = 'N';
            }
            this.dataTable = dataTable;

            for (var i in productContentData.horsepower) {
                this.motor = productContentData.horsepower[i].Motor;
                this.horsePower = productContentData.horsepower[i].Horsepower;
                this.transmissionDescription = productContentData.horsepower[i].TransmissionDescription;
            }

            this.viewReport = true;
            setTimeout(() => { this.reportProgress = false }, 500);
        }
    }

    getEmailProperties(emailType) {        
        this.to = "";
        this.from = "";
        this.subject = "";
        this.message = "";
        this.cc = "";
        this.bcc = "";
        this.releaseEmails = "";
        this.emailService.getEmailProperties(emailType).subscribe((properties) => {
            this.emailProperties = properties;

            if (this.emailProperties.errors != null) {
                this.msg = "Error Fetching email properties";
            }

            this.from = this.emailProperties.emailFrom;
            this.subject = this.emailProperties.emailSubject;
            this.message = this.emailProperties.emailBody;
            this.releaseEmails = this.emailProperties.releaseEmails;
            this.cc = this.emailProperties.emailCCTo;
            this.bcc = this.emailProperties.emailBCCTo;

            if (this.releaseEmails === "Y") {
                this.to = this.emailProperties.emailTo;
            } else {                
                this.to = this.bcc;
            }            
        });
    }

    to: string;
    from: string;
    subject: string;
    message: string;
    cc: string;
    bcc: string;
    releaseEmails: string;

    approveData() {
        this.approveProgress = true;
        this.msg = "";
        this.getEmailProperties('ProductContentApproval');
        const emailproperties = this.emailProperties;

        this.productContentService.approveProductContentData(this.selectedYear, this.selectedModelType, this.selectedTransmission, this.selectedVIN).then((result) => {
            if (result) {
                this.msg = "Approval complete.";
                let approveButton = <HTMLButtonElement>document.getElementById('approveButton');
                approveButton.disabled = true;
                
                let message = "Model Year: " + this.selectedYear + ", Model Type: " + this.selectedModelType + ", Transmission: " + this.model.transmission + ", VIN: " + this.selectedVIN;
                this.message = message + " " + this.message;
                this.emailService.sendEmail(this.from, this.to, this.subject, this.message, this.cc, this.bcc).then((res) => {
                    
                });
            }
            else
                this.msg = "Approval Failed.";
        });
        setTimeout(() => { this.approveProgress = false }, 500);
        // this.productContentService.approveProductContentData(this.selectedYear, this.selectedModelType, this.selectedTransmission, this.selectedVIN).subscribe({
        //     next:
        //         (approvedResponse) => {
        //             this.approvedResponse = (approvedResponse)
        //             this.reportProgress = false;
        //             this.msg = "Approval complete.";
        //             setTimeout(() => {
        //                 this.msg = "";
        //             }, 10000)
        //             this.changeDetector.detectChanges();
        //         },
        //     error: (error) => {
        //         if (error !== undefined && error.length < 250) {
        //             this.msg = "";
        //         } else {
        //             this.msg = "";
        //         }
        //         setTimeout(() => {
        //             this.msg = "";
        //         }, 10000)
        //         this.changeDetector.detectChanges();
        //     }
        // })        
    }

    async viewLabel() {
        this.labelProgress = true;
        this.msg = "";
        var vins = [];
        vins.push(this.selectedVIN);

        await this.apiService.getLabelProofForProduct(environment.optionsDisclosureTemplateID, this.selectedYear, this.selectedModelType, this.selectedTransmission, vins).subscribe((res) => {            
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(res);
            var dd = new Date;
            var date = new Date().getFullYear() + "-" + (dd.getMonth() + 1) + "-" + dd.getDate();
            var seconds = dd.getTime() / 1000;
            var fileName = "Product_Content" + this.selectedYear + "_" + this.selectedModelType + "_" + date + "_" + seconds.toString() + ".pdf";
            // link.setAttribute('download', fileName);
            link.setAttribute('target', '_blank');
            link.click();
            this.labelProgress = false;
        }), error => {
            console.error("Error occured with the API response: " + error);
        }

    }
}
