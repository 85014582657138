import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { Observable } from 'rxjs';

import { LoginService } from '../core/login.service';
import { LabelPreviewModel } from '../model/labelPreviewModel';

@Injectable({
    providedIn: 'root'
})
export class LabelPreviewService {

    constructor(private http: HttpClient, private loginService: LoginService) { }

    public async updateLegalApprovalFlag(labelPreview: LabelPreviewModel): Promise<LabelPreviewModel> {
        const result = await axios.post<LabelPreviewModel>(`${environment.serviceUrl}secure/api/updateLegalApprovalFlag`, labelPreview,
            {
                headers: {
                    'Authorization': this.getToken(),
                    'Content-Type': 'application/json'
                }
            });
        return result.data;
    }

    protected getToken(): string {
        return 'Bearer ' + sessionStorage.getItem("token");
    }
}