import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'is-what';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { LoginService } from '../login.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

    constructor(private router: Router,
        public loginService: LoginService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title) {
        this.titleService.setTitle("Porsche - Reset Password");
    }

    ngOnInit() {
        const userSession = this.loginService.getUserSession();
        if (isNullOrUndefined(userSession)) {
            this.router.navigate(['login']);
        }
    }
}