import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactPageComponent } from './contact/contact.component';
import { LoginComponent } from './core/login/login.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { ProductContentComponent } from './productContent/productContent.component';
import { FuelEconomyComponent } from './fuelEconomy/fuelEconomy.component';
import { PartsContentComponent } from './partsContent/partsContent.component';
import { AdminPageComponent } from './admin/adminPage.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PreviewLabelComponent } from './labelPreview/labelPreview.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'resetPassword', component: ResetPasswordComponent }, 
  { path: 'home', component: HomeComponent },
  { path: 'contactPage', component: ContactPageComponent },
  { path: 'fuelEconomy', component: FuelEconomyComponent },
  { path: 'partsContent', component: PartsContentComponent },
  { path: 'productContent', component: ProductContentComponent },
  { path: 'adminPage', component: AdminPageComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'previewLabel', component: PreviewLabelComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })]
})
export class AppRoutingModule {

}