import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { Observable } from 'rxjs';

import { LoginService } from '../core/login.service';

import { AdminPage } from '../model/adminPage';
import { AdminPageComponents } from '../model/adminPageComponents';

@Injectable({
    providedIn: 'root'
})
export class AdminPageService {

    constructor(private http: HttpClient, private loginService: LoginService) { }

    public async getAdminPageComponents(year: string, modelType: string, transmission: string): Promise<Observable<AdminPage>> {
        return this.http.get<AdminPage>(`${environment.serviceUrl}secure/api/getAdminPageComponents/${year}/${modelType}/${transmission}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            });
    }

    public async updateorAddAdminPageComponents(adminComponents: AdminPageComponents): Promise<AdminPageComponents> {
        const result = await axios.post<AdminPageComponents>(`${environment.serviceUrl}secure/api/updateorAddAdminPageComponents`, adminComponents,
            {
                headers: {
                    'Authorization': this.getToken(),
                    'Content-Type': 'application/json'
                }
            });
        return result.data;
    }

    protected getToken(): string {
        return 'Bearer ' + sessionStorage.getItem("token");
    }
}