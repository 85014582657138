import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: "root"
})
export class GlobalErrorHandler implements ErrorHandler {

  handleError(error: any) {
    if (error) {
      console.log(error)
    }
  }

}