export const environment = {
  production: false,
  serviceUrl: '',
  programName: 'Porsche',
  fromEmail: 'smtpuser@helm.com',
  fromEmailName: 'Porsche',
  portalURL: 'Dashboard URL: https://porschedashboard.uat.helm.com/',
  partsContentTemplateID: '18',
  optionsDisclosureTemplateID: '19',
  fuelEconomyTemplateID: '20',
  combinedElementsTemplateID: '21',
  resetPasswordEmailSubject: 'Porsche Password Reset',
  resetPasswordEmailBody: `<html><body>
      <p>This email has been sent because a password reset has been requested for your account with Porsche.</p>
      <p>If for some reason you encounter a problem, please do not request a password change again, contact Program Headquarters at .</p>
      <p>If you did not initiate the request, you do not need to take any further action and can safely disregard this email. Your current password will not be changed.</p>
      <p>If you want to reset your password, please click on the link below, and you will be directed to a page that will allow you to reset your password.</p>
      </body></html>`
};
