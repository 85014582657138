import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenVerificationService } from './token-verification.service';
import { LoginService } from 'src/app/core/login.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService 
{
  private verificationService: TokenVerificationService;
  private outputToken: string;

  constructor(http: HttpClient, private loginService: LoginService) 
  {
    this.verificationService = new TokenVerificationService(http);
  }

  async login(username: string, password: string): Promise<string> 
  {
    this.outputToken = "";
    try {
      let data = await this.verificationService.getToken(username, password);

      if (data.errors) { 
        console.log("errors true!") 
      } else {
        await this.loginService.isValidToken(data.data);
      }

      this.outputToken = data.data;
      return this.outputToken;
    } catch (error) {
      console.log("errors!")
      console.log(error.error.errors)
      throw error.error.errors;
    }
  }

  logout()
  {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
  }
}
