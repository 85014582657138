import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import axios from 'axios';

import { PartsContent } from '../model/partsContent';
import { PartsContentUpdate } from '../model/partsContentUpdate';
import { LoginService } from '../core/login.service';

@Injectable({
    providedIn: 'root'
})
export class PartsContentService {

    constructor(private http: HttpClient, private loginService: LoginService) { }

    public async getPartsContentData(modelYear: string, modelType: string, transmission: string): Promise<PartsContent> {
        return this.http.get<PartsContent>(`${environment.serviceUrl}secure/api/getPartsContentData?modelYear=${modelYear}&modelType=${modelType}&transmission=${transmission}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            }).toPromise();
    }

    public async updatePartsContentData(partsContent: PartsContentUpdate): Promise<PartsContentUpdate> {        
        const result = await axios.post<PartsContentUpdate>(`${environment.serviceUrl}secure/api/updatePartsContentData`, partsContent,            
            {
                headers: {
                    'Authorization': this.getToken(),
                    'Content-Type': 'application/json'
                }
            });
        return result.data;
    }

    public async approvePartsContentData(modelYear: string, modelType: string, transmission: string) {
        const userID = this.loginService.getUserSession()['userName'];
        const result = await axios.post<boolean>(`${environment.serviceUrl}secure/api/approvePartsContentData`,
            { modelYear: modelYear, modelType: modelType, transmission: transmission, userID: userID },
            {
                headers: {
                    'Authorization': this.getToken(),
                    'Content-Type': 'application/json'
                }
            });
        return result.data;
    }

    protected getToken(): string {
        return 'Bearer ' + sessionStorage.getItem("token");
    }
}